@import '../../../node_modules/@cx/theme/src/base/_cx-bootstrap-variables.scss';

#CxComponentTopNav_rims {
  // flex-direction: column;
  // width: 100%;
  padding: 0;
  background-color: white;
  height: auto;
  // .ant-menu li {
  //   padding-top: 4px;
  //   padding-bottom: 4px;
  //   line-height: 26px;
  //   svg {
  //     height: 20px;
  //     width: 20px;
  //     line-height: 18px;
  //   }
  // }
  .connectLogo {
    height: 25px;
    margin: 8px;
    float: left;
    width: 100px;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url("/images/Connect_logo.png")
  }
  .ant-menu-horizontal {
    line-height: normal;
  }
}
#kpisection {
  zoom: 0.6;
}

.ant-menu-item-group {
  .ant-menu-item-group-title {
    display: none;
  }
  li.ant-menu-submenu-horizontal {
    display: inline-block;
  }
}
.tabsinnav {
  padding: 8px;
  .ant-tabs-bar {
    text-align: center;
  }
}
.nav-dropdown-button-bottom {
  border-top: 1px solid #ddd;
  text-align: center;
  width: 100%;
}
// #Navigation {
//   display: inline-block;
// }
#PersonMenu {
  #userSelector {
    padding: 0;
    padding-left: 18px;
    font-size: 8px;
    color: black;
    margin-top: -8px;
    display: block;
  }
  .ant-list {
    border-top: 1px solid #ddd;
    .ant-list-item {
      padding: 0;
      .btn-link {
        color: inherit;
      }
    }
  }
}
