@import '../node_modules/@cx/theme/src/base/_cx-bootstrap-variables.scss';

@import "~antd/dist/antd.css";

.ant-menu-submenu-popup::before {
  content: none !important;
}
.clear {
  clear: both;
}
#root {
  height: 100%;
  & > div {
    height: 100%; // No idea why this div is here
  }
  #CarDashboard-CX {
    display: flex;
    flex-direction: column;
    height: 100%;

    #cardashboardframeContainer {
      background-color: #dddddd;
      flex-grow: 1;
      height: 100%;
      iframe {
        height: 100%;
      }
      .cx-loadingIndicator {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgba(5, 5, 5, 0.5);
        & > div {
          align-items: center;
          height: 100%;
        }
      }
    }
  }
}
